import React from 'react';
import { AdSlot } from 'react-dfp';
import isBrowser from 'utils/isBrowser';
import isMobile from 'utils/isMobile';

export const FooterAd: React.FunctionComponent<any> = (() => {
    if (!isBrowser()) {
        return null;
    }
    return (

        <div>
            {!isMobile() && 
            <div className="desktop-ads">
                <AdSlot targetingArguments={{ pagepos: '1501', page: location.pathname, href: location.href }} sizes={[[728, 90]]} />
            </div>
            }
            {isMobile() && 
            <div className="mobile-ads">
                <AdSlot targetingArguments={{ pagepos: '1501', page: location.pathname, href: location.href }} sizes={[[320, 50]]} />
            </div>
            }
        </div>
    )
});

export default FooterAd;