import React from 'react';
import Layout from 'components/Layout';
import { StyledSection } from './styles';

const Footer: React.FunctionComponent<any> = ({ children }) => (
  <StyledSection>
    <Layout.Content>
      <Layout.ContentInner>{children}</Layout.ContentInner>
    </Layout.Content>
  </StyledSection>
);

export default Footer;
