import styled from 'styled-components';

export const PartnerWrapper = styled.div(
  ({ theme: { easing, tabletView, mobileView, spacing } }) => `
    grid-area: partners;
    padding-top: 38px;
    padding-bottom: 29px;

    > div {
      display: grid;
      grid-template-columns: repeat(auto-fit, 120px);
      grid-gap: ${spacing.x1};
      justify-items: center;
      align-items: center;
      justify-content: center;
      height: 58px;

      ${mobileView} {
        height: auto;
        padding-bottom: ${spacing.x2};
        grid-template-columns: repeat(3, 1fr);
      }

      ${mobileView} {
        padding-bottom: 8px;
      }

      a {
        display: block;
        width: 100%;
        padding: 0 7%;
        transition: transform .4s ${easing};
        max-height: 58px;

        img {
          display: block;
          width: auto;
          max-width: 100%;
          height: 100%;
          max-height: 58px;
          margin: 0 auto;
          object-fit: contain;
        }

        &:hover {
          transform: scale(1.1);
        }
      }

      &:last-of-type {
          a {
            padding-bottom: 0;
          }
        }
      }
    }
  `,
);
