import React from 'react';
import Artist from '../Artist';
import ArtistConsumer from 'context/ArtistContext';
import SlideMenu from '../SlideMenu';
import ContentWrapper from './styles';

const ArtistMenu: React.StatelessComponent<{ showSubscribeModal: () => void }> = ({ showSubscribeModal }) => (
  <ArtistConsumer>
    {({ artist, updateArtist }) => (
      <SlideMenu isOpen={!!artist} close={() => updateArtist()} scroll={true} width="50%" maxWidth="720px" mobileWidth="80%" right={true}>
        <ContentWrapper>
          {artist && (
            <Artist
              showSubscribeModal={() => {
                updateArtist();
                showSubscribeModal();
              }}
              {...artist}
            />
          )}
        </ContentWrapper>
      </SlideMenu>
    )}
  </ArtistConsumer>
);

export default ArtistMenu;
