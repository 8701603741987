import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { PartnerWrapper } from './styles';
import Image from 'components/Image';

export const Partners: React.StatelessComponent<any> = () => (
  <StaticQuery
    query={graphql`
          query Partners {
            wp {
              acfOptionsThemeSettings {
                  themeOptions {
                    secondaryPartners {
                      ... on WpPartner {
                        id
                        title
                        partnerLink {
                          link
                        }
                        featuredImage {
                          node {
                            ...Image
                          }
                        }
                      }
                    }
                    topPartners {
                      ... on WpPartner {
                        id
                        title
                        partnerLink {
                          link
                        }
                        featuredImage {
                          node {
                            ...Image
                          }
                        }
                      }
                    }
                  }
                }
            }
          }
        `}
    render={({ wp: { acfOptionsThemeSettings: { themeOptions } } }) => {
      return (
      <PartnerWrapper>
        {themeOptions && themeOptions.topPartners && (
          <div>
            {themeOptions.topPartners.length &&
              themeOptions.topPartners.map(
                ({ featuredImage, partnerLink: { link } }, index) =>
                {
                  const feature = featuredImage?.node ? featuredImage.node : null;
                  return (
                  <a key={`a-${index}`} href={link ? link : '#'} target={link ? '_blank' : ''}>
                    <Image {...feature} />
                  </a>
                )},
              )}
          </div>
        )}
        {themeOptions && themeOptions.secondaryPartners && (
          <div style={{marginTop: '32px'}}>
            {themeOptions.secondaryPartners.length &&
              themeOptions.secondaryPartners.map(
                ({ featuredImage, partnerLink: { link } }, index) =>
                {
                const feature = featuredImage?.node ? featuredImage.node : null;
                return (
                  <a key={`a-${index}`} href={link ? link : '#'} target={link ? '_blank' : ''}>
                    <Image {...feature} />
                  </a>
                )},
              )}
          </div>
        )}
      </PartnerWrapper>
    )}}
  />
);

export default Partners;
