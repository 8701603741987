const socials = {
  facebook: 'https://www.facebook.com/splendourinthegrass',
  instagram: 'https://www.instagram.com/splendourinthegrass',
  twitter: 'https://twitter.com/sitg',
  spotify: 'https://play.spotify.com/user/splendourinthegrassfestival',
};

export const socialsWithEmail = {
  ...socials,
  email: 'mailto:',
};

export default socials;
