import React, { useEffect } from 'react';
import Layout from 'components/Layout';
import { useOnScreen } from 'hooks';
import { StyledSection } from './styles';

interface Props {
  children: React.ReactNode;
  isFirstSection?: boolean;
  pageHasAnnouncement?: boolean;
  onVisible?: () => void;
  color?: String;
}

const Block: React.FunctionComponent<Props> = ({
  children,
  isFirstSection = false,
  pageHasAnnouncement = false,
  color,
  onVisible = () => {},
  ...other
}: Props) => {
  const [sectionRef, sectionEntry] = useOnScreen({ rootMargin: '-200px' });

  useEffect(() => {
    if (sectionEntry && sectionEntry.isIntersecting) {
      onVisible();
    }
  }, [sectionEntry]);

  return (
    <StyledSection ref={sectionRef} isFirstSection={isFirstSection} color={color} {...other}>
      <Layout.Content isFirstSection={isFirstSection} pageHasAnnouncement={pageHasAnnouncement}>
        <Layout.ContentInner>{children}</Layout.ContentInner>
      </Layout.Content>
    </StyledSection>
  );
};

export default Block;
