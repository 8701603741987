import styled from 'styled-components';

export default styled.div(
  ({ theme: { spacing, mobileView } }) => `
    z-index: 1;
    padding: 0 ${spacing.x4};

   ${mobileView} {
    padding: 0;
  }
`,
);
