
import React from 'react';
import SOCIALS from 'constants/socials';
import WEBSITES from 'constants/websites';
import { PageLinkType } from 'types';
import IconButton from 'components/IconButton';
import Button from 'components/Button';
import SocialLinks from 'components/SocialLinks';
import LogoImage from 'assets/images/splendour-xr-logo.png';
import MountainLeft from 'assets/images/mountain-left.png';
import MountainRight from 'assets/images/mountain-right.png';
import Edge from 'assets/images/flower-bkg-top.png';
import Unreal from 'assets/images/unreal-tagline.png';
import Image from 'components/Image';
import Partners from 'components/Partners';
import { ArrowUp } from 'icons';
import FooterWrapper, {
  Acknowledgement,
  BackToTop,
  Logo,
  ContentWrapper,
  Credits,
  FooterInner,
  FooterLandscape,
  SubscribeWrapper,
  SiteLinks,
  SocialLinksWrapper,
  ArrowButton,
} from './styles';

const handleScroll = (pageWrapId) => {
  const page = pageWrapId ? document.getElementById(pageWrapId) : window;

  if (page) {
    page.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
};

interface Props {
  mainMenuItems: PageLinkType[];
  secondaryFooterItems?: PageLinkType[];
  partnerItems?: any;
  partnerTopItems?: any;
  pageWrapId?: string;
  openSubscribeMenu: () => void;
  language?: any;
}

const Footer: React.FunctionComponent<Props> = ({
  secondaryFooterItems,
  pageWrapId,
  openSubscribeMenu,
  language,
}) => (
  <FooterWrapper>
    <FooterLandscape>
      <Image className="mountain-left" sourceUrl={MountainLeft} />
      <Image className="sun" sourceUrl={Unreal} />
      <Image className="edge" sourceUrl={Edge} />
      <Image className="mountain-right" sourceUrl={MountainRight} />
    </FooterLandscape>
    <FooterInner>
      <ContentWrapper>
        {language && language.code === 'EN' &&
        <Acknowledgement className="p4">
          <p>In the spirit of reconciliation Secret Sounds acknowledges the Traditional Custodians of country throughout Australia and their connections to land, sea and community. We pay our respect to their elders past and present and extend that respect to all Aboriginal and Torres Strait Islander peoples today.</p>
        </Acknowledgement>
        }
        <Partners />
        <SocialLinksWrapper>
          <SocialLinks links={SOCIALS} />
        </SocialLinksWrapper>
        <Logo>
          <Image sourceUrl={LogoImage} />
        </Logo>
        <SubscribeWrapper>
          <BackToTop>
            <ArrowButton onClick={() => handleScroll(pageWrapId)} size="medium" color="whitePink" inverted={true}>
              <span>
                <span><ArrowUp /></span>
              </span>
            </ArrowButton>
          </BackToTop>
          <IconButton type="paperPlane" size="small" text="Subscribe" inverted={true} color="black" onClick={openSubscribeMenu} />
        </SubscribeWrapper>
        {secondaryFooterItems && (
          <SiteLinks>
            {secondaryFooterItems.map(({ label: title, url }: PageLinkType, index) => (
              <a key={index} href={url}>
                <span className="h9 subcontent">{title}</span>
              </a>
            ))}
          </SiteLinks>
        )}
        <Credits className="h9 subcontent">
          <span>© Copyright SplendourXR {new Date().getFullYear()}</span>
          <div className="separator">{'•'}</div>
          <span>Original Art & Creative by Lucy Dyson</span>
          <div className="separator">{'•'}</div>
          <span>
            Website by{' '}
            <a href={WEBSITES.BOLSTER} target="_blank">
              Bolster
            </a>
          </span>
        </Credits>
        <div className="cookie-button"><button id="ot-sdk-btn" className="ot-sdk-show-settings" style={{margin: '16px 0'}}>Cookie Settings</button></div>
      </ContentWrapper>
    </FooterInner>
  </FooterWrapper>
);

export default Footer;
