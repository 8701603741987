import styled from 'styled-components';
import Layout from 'components/Layout';
import theme from 'styles/theme';

export const StyledSection = styled(Layout.Section)`
  color: ${theme.colors.black};
  position: relative;
  z-index: 1;
  font-size: inherit;
  ${Layout.ContentInner} {
    max-width: none;
  }
`;
