import React from 'react';
import isHeadless from 'utils/isHeadless';
import AbstractPage from './AbstractPage';
import BlockLayout from './BlockLayout';
import isMobile from 'utils/isMobile';
import { BackgroundNoise } from './styles';

class BlockPage extends AbstractPage {
  state = {
    announcement: null,
    loaded: false,
    headless: isHeadless(),
    showModal: false,
    index: 0,
  };
  timeout;

  updateBackgroundForIndex(index) {
    
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState({
        index,
      });
    }, 200);
  }

  render() {
    const { announcement, loaded, headless, index } = this.state;
    const {
      mainMenu,
      footerMenu,
      secondaryFooterMenu,
      footerTopPartners,
      footerSecondaryPartners,
      content,
    } = this.props;
    
    const children = this.renderChildren();

    return (
      <BlockLayout
        headerMenu={mainMenu?.menuItems?.nodes && mainMenu.menuItems.nodes}
        mainMenu={mainMenu?.menuItems?.nodes && mainMenu.menuItems.nodes}
        footerTopPartners={footerTopPartners && footerTopPartners}
        footerSecondaryPartners={footerSecondaryPartners && footerSecondaryPartners}
        footerMenu={footerMenu?.menuItems?.nodes && footerMenu.menuItems.nodes}
        secondaryFooterMenu={secondaryFooterMenu?.menuItems?.nodes && secondaryFooterMenu.menuItems.nodes}
        announcement={announcement}
        hideAnnouncement={this.hideAnnouncement}
        seo={content && content.seo}
        headless={headless}
        language={content.language}
      >
        {!isMobile() && <BackgroundNoise />}
        {children}
      </BlockLayout>
    );
  }
}

export default BlockPage;
